<template>
  <ion-page>
    <header-toolbar>
      <ion-title>{{ __('interface.devices') }}</ion-title>
    </header-toolbar>
    <ion-content :fullscreen="true">
      <ion-list-header v-text="__('interface.my-devices')"></ion-list-header>
      <ion-item-sliding v-for="(device, key) in myDevices" :key="key">
        <ion-item>
          <ion-icon v-if="statuses[device.name]" :icon="checkmarkOutline" slot="start" color="primary"/>
          <ion-label v-text="device.name" @click="connectOrDisconnect(device)"></ion-label>
          <ion-chip v-if="statuses[device.name] && batteryLevel > 0" slot="end" color="primary">
            <ion-icon v-if="batteryLevel > 60" :icon="batteryFullOutline"></ion-icon>
            <ion-icon v-else-if="batteryLevel > 10" :icon="batteryHalfOutline"></ion-icon>
            <ion-icon v-else :icon="batteryDeadOutline"></ion-icon>
            <ion-label v-text="displayBatteryLevel"></ion-label>
          </ion-chip>
          <ion-chip v-if="!statuses[device.name] && connectStatuses[device.name]" slot="end" color="primary"
                    v-text="__('interface.pair')"
                    @click="connectOrDisconnect(device)"></ion-chip>
          <ion-chip v-if="!statuses[device.name] && !connectStatuses[device.name]" slot="end" color="danger"
                    v-text="__('interface.not-ready')"></ion-chip>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option color="danger" v-text="__('interface.remove')" @click="remove(device)"></ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
      <ion-item v-if="myDevices.length <= 0">
        <ion-label v-text="__('interface.no-devices')"></ion-label>
      </ion-item>
      <ion-list-header v-text="__('interface.other-devices')"></ion-list-header>
      <ion-item v-for="(device, key) in otherDevices" :key="key" @click="connectOrDisconnect(device)">
        <ion-label v-text="device.name"></ion-label>
      </ion-item>
      <ion-item v-if="otherDevices.length <= 0">
        <ion-label v-text="__('interface.no-devices')"></ion-label>
      </ion-item>
      <ion-button expand="full" class="ion-margin-vertical" :disabled="loadingState" @click="scan">
        <span v-if="loadingState">{{ __('interface.scanning') }}</span>
        <span v-else>{{ __('interface.scan') }}</span>
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonIcon,
  IonPage,
  IonListHeader,
  IonItemSliding,
  IonItemOptions,
  IonItemOption, alertController
} from "@ionic/vue";
import {
  batteryDeadOutline,
  batteryFullOutline,
  batteryHalfOutline,
  checkmarkOutline,
  refreshOutline
} from 'ionicons/icons';
import AuthView from '@/views/AuthService';
import HeaderToolbar from '../layouts/HeaderToolbar';
import {mapActions} from "vuex";
import ble from "cordova-plugin-ble-central/www/ble";
import {LocationAccuracy} from "@ionic-native/location-accuracy";
import {Capacitor} from '@capacitor/core';

export default {
  name: "DevicesIndex",
  mixins: [AuthView],
  components: {
    HeaderToolbar,
    IonPage,
    IonIcon,
    IonListHeader,
    IonItemSliding,
    IonItemOptions,
    IonItemOption
  },
  computed: {
    myDevices() {
      return this.$store.state.ble.myDevices ?? [];
    },
    otherDevices() {
      return this.$store.state.ble.otherDevices ?? [];
    },
    statuses() {
      return this.$store.state.ble.statuses ?? {};
    },
    connectStatuses() {
      return this.$store.state.ble.connectStatuses ?? {};
    },
    loadingState() {
      return this.$store.state.ble.loadingState ?? false;
    },
    batteryLevel() {
      return this.$store.state.ble.batteryLevel ?? 0;
    },
    displayBatteryLevel() {
      return this.batteryLevel + '%';
    },
  },
  data() {
    return {
      heartRate: 0,
    }
  },
  methods: {
    ...mapActions({
      scan: 'ble/scan',
      connect: 'ble/connect',
      disconnect: 'ble/disconnect',
      remove: 'ble/remove',
    }),
    async connectOrDisconnect(device) {
      if (this.statuses[device.name]) {
        await this.disconnect(device);
      } else {
        if (this.connectStatuses[device.name]) {
          await this.connect(device);
        }
      }
    },
    async assurePermissions(success) {
      if (Capacitor.platform === 'android') {
        ble.enable(
          async () => {
            await LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_BALANCED_POWER_ACCURACY).then(async () => {
                await success()
              }
            )
          }
        )
      }

      if (Capacitor.platform === 'ios') {
        ble.isEnabled(
          async () => {
            await success()
          },
          async () => {
            const alert = await alertController.create({
              header: this.__('interface.ble-enable-alert-header'),
              message: this.__('interface.ble-enable-alert-message'),
              buttons: [this.__('interface.ok')],
            });
            return alert.present();
          }
        )
      }
    },
  },
  async ionViewWillEnter() {
    await this.checkAuth();
  },
  async ionViewDidEnter() {
    await this.assurePermissions(this.scan)
  },
  setup() {
    return {
      refreshOutline,
      checkmarkOutline,
      batteryFullOutline,
      batteryHalfOutline,
      batteryDeadOutline
    }
  },

}
</script>
